import ja from 'vuetify/src/locale/ja.ts'

export default {
  ...ja,

  dataFooter: {
    ...ja.dataFooter,
    pageText: '{2}件中 {0}-{1}件を表示中'
  }
}
