<template>
  <v-app class="pa-6">
    <Header />
    <Sidebar />
    <v-main class="content">
      <router-view />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Header from '@/components/admin/partials/Header/Header'
import Sidebar from '@/components/admin/partials/Sidebar/Sidebar'
import Footer from '@/components/admin/partials/Footer/Footer'
import './Layout.scss'

export default {
  name: 'Layout',
  components: { Footer, Header, Sidebar }
}
</script>

<style src="./Layout.scss" lang="scss" />
