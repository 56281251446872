import axios from 'axios'
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  UPDATE_USER
} from '@/store/actions/auth'
import router from '@/router/index.js'

function initialState() {
  return {
    token: '',
    status: '',
    user: null
  }
}

const state = {
  token: '',
  status: '',
  user: null
}

const getters = {
  token: state => state.token,
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  user: state => state.user
}

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit('AUTH_REQUEST')
      axios({
        url: '/login',
        data: user,
        method: 'POST'
      })
        .then(resp => {
          const token = resp.data.token
          const user = resp.data.data
          user.full_name = user.last_name + ' ' + user.first_name
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          commit('AUTH_SUCCESS', { token, user })
          resolve(resp)
        })
        .catch(err => {
          commit('AUTH_ERROR')
          reject(err)
        })
    })
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      axios.get('/logout').then(resp => {
        if (resp.data.status == 'success') {
          commit('AUTH_LOGOUT')
        }
      })
      resolve()
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.token = params.token
    state.user = params.user
  },
  [AUTH_ERROR]: state => {
    state.status = 'error'
  },
  [AUTH_LOGOUT]: state => {
    state.token = ''
    router.push({ name: 'Login' }).catch(() => {})

    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
    window.localStorage.clear()
    state.status = ''
    state.user = null
  },
  [UPDATE_USER]: (state, params) => {
    Object.keys(params).forEach(key => {
      state.user[key] = params[key]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
