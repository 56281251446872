import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'

import Layout from '@/components/admin/layout/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      title: 'ログイン',
      public: true, // public => can access those pages without login
      guest: true // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      title: 'パスワード再設定',
      public: true,
      guest: true
    }
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      title: 'パスワード再設定',
      public: true,
      guest: true
    }
  },
  {
    path: '/',
    redirect: 'login',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: 'work',
        name: 'Post',
        component: () => import('@/views/post/PostList.vue'),
        meta: { title: '施工事例一覧' }
      },
      {
        path: '/work/create',
        name: 'CreatePost',
        component: () => import('@/views/post/PostCreate.vue'),
        meta: { title: '施工事例の新規作成' }
      },
      {
        path: '/work/:id',
        name: 'EditPost',
        component: () => import('@/views/post/PostEdit.vue'),
        meta: { title: '施工事例の編集' }
      },
      {
        path: '/work/preview/:id',
        name: 'PreviewPost',
        component: () => import('@/views/post/Preview.vue'),
        meta: { title: 'Preview Post' }
      },
      {
        path: 'quote',
        name: 'Quote',
        component: () => import('@/views/quote/QuoteList.vue'),
        meta: { title: '見積申請一覧' }
      },
      {
        path: 'quote/view/:id',
        name: 'QuoteView',
        component: () => import('@/views/quote/QuoteView.vue'),
        meta: { title: '見積詳細' }
      },
      {
        path: 'tags',
        name: 'Tags',
        component: () => import('@/views/tags/TagList.vue'),
        meta: { title: 'タグ一覧' }
      },
      {
        path: 'tags/create',
        name: 'TagsCreate',
        component: () => import('@/views/tags/TagCreate.vue'),
        meta: { title: 'Tag Create' }
      },
      {
        path: 'tags/edit/:id',
        name: 'TagsEdit',
        component: () => import('@/views/tags/TagEdit.vue'),
        meta: { title: 'タグの編集' }
      },
      {
        path: 'categories',
        name: 'Categories',
        component: () => import('@/views/categories/CategoryList.vue'),
        meta: { title: 'カテゴリー一覧' }
      },
      {
        path: 'categories/create',
        name: 'CategoriesCreate',
        component: () => import('@/views/categories/CategoryCreate.vue'),
        meta: { title: 'Category Create' }
      },
      {
        path: 'categories/edit/:id',
        name: 'CategoriesEdit',
        component: () => import('@/views/categories/CategoryEdit.vue'),
        meta: { title: 'カテゴリーの編集' }
      },
      {
        path: 'account',
        name: 'AccountSetting',
        component: () => import('@/views/user/AccountSetting.vue'),
        meta: { title: 'アドミンアカウントの設定' }
      }
    ]
  },
  {
    path: '*',
    name: 'Error',
    component: () => import('@/views/error/Error.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: '/mtclsecuredlogin',
  routes
})

// Auth guard
router.beforeEach((to, from, next) => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? to.meta.title + ' | 三光住建 管理者パネル'
      : '三光住建 管理者パネル'
  })
  const authRequired = !to.matched.some(record => record.meta.public)

  const loggedIn = store.getters.token
  if (authRequired && !loggedIn) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else if (!authRequired && loggedIn) {
    next('/quote')
    return
  }
  next()
})

export default router
