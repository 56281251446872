import axios from 'axios'

export default {
  add(url, params) {
    return axios.post(url, params)
  },

  get(url, params = null) {
    return axios.get(url, { params })
  },

  update(url, params) {
    return axios.put(url, params)
  },

  delete(url, params = null) {
    return axios.delete(url, params)
  },

  post(url, params = null) {
    return axios.post(url, params)
  }
}
