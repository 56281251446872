import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './modules/auth'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: true,
    alert: null
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer
    },
    setAlert(state, payload) {
      state.alert = payload
    },
    resetAlert(state) {
      state.alert = null
    }
  },
  actions: {
    TOGGLE_DRAWER({ commit }) {
      commit('toggleDrawer')
    }
  },
  getters: {
    DRAWER_STATE(state) {
      return state.drawer
    }
  },
  modules: {
    auth
  },
  plugins: [createPersistedState()]
})
