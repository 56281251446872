<template>
  <v-navigation-drawer
    app
    clipped
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"
    :class="{ 'drawer-mini': !DRAWER_STATE }"
  >
    <v-list nav dense>
      <template v-for="(item, i) in items">
        <v-row v-if="item.heading" :key="item.heading" align="center">
          <v-col cols="6">
            <span
              style="padding-left: 32px"
              class="text-body-1 subheader"
              :class="item.heading && DRAWER_STATE ? 'show ' : 'hide'"
            >
              {{ item.heading }}
            </span>
          </v-col>
          <v-col cols="6" class="text-center"> </v-col>
        </v-row>
        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-1"
        ></v-divider>
        <v-list-group
          color="primary"
          v-else-if="item.children"
          :key="item.title"
          v-model="item.model"
        >
          <template v-slot:prependIcon>
            <v-icon size="20">{{ item.icon }}</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="grey-darken-1--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.link"
            link
          >
            <v-list-item-action v-if="child.icon">
              <!-- <v-icon size="20">A</v-icon> -->
              <span>{{ child.iconText }}</span>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey-darken-1--text">
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          color="primary"
          v-else
          :key="item.text"
          :to="item.link === '#' ? null : item.link"
          link
        >
          <v-list-item-action>
            <v-icon size="20" :color="item.color ? item.color : ''">{{
              item.icon
            }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              class="grey-darken-1--text align-badge-status"
              link
            >
              <span>{{ item.title }}</span>
              <v-badge
                v-if="item.badge && quoteShowHide"
                color="primary"
                class="my-badge-class"
                :content="quotesStatusCount"
              >
              </v-badge>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import api from '@/services/api-methods'
import { EventBus } from '@/services/event-bus.js'

export default {
  props: {
    source: String
  },
  data() {
    return {
      items: [
        {
          title: '見積管理',
          icon: 'mdi-format-quote-open',
          link: '/quote',
          badge: true
        },
        { title: '施工事例管理', icon: 'mdi-note-text', link: '/work' },
        { title: 'カテゴリー管理', icon: 'mdi-view-list', link: '/categories' },
        { title: 'タグ管理', icon: 'mdi-tag', link: '/tags' }
      ],
      sidebarWidth: 240,
      sidebarMinWidth: 76,
      quotesStatusCount: 0,
      quoteShowHide: true
    }
  },
  computed: {
    ...mapState(['drawer']),
    DRAWER_STATE: {
      get() {
        return this.drawer
      },
      set(newValue) {
        if (newValue === this.drawer) return
        this.TOGGLE_DRAWER()
      }
    }
  },
  mounted() {
    this.getQoutesCount()
    EventBus.$on('status_count', res => {
      if (res == 'increment') {
        this.quotesStatusCount++
      } else {
        this.quotesStatusCount--
      }
    })
  },
  beforeMount() {
    if (this.quotesStatusCount == 0) {
      this.quoteShowHide = false
    } else {
      this.quoteShowHide = true
    }
  },
  watch: {
    quotesStatusCount() {
      if (this.quotesStatusCount == 0) {
        this.quoteShowHide = false
      } else {
        this.quoteShowHide = true
      }
    }
  },
  methods: {
    ...mapActions(['TOGGLE_DRAWER']),
    getQoutesCount() {
      api
        .get('/get-final-step-count')
        .then(response => {
          this.quotesStatusCount = `${response.data.count}`
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style src="./Sidebar.scss" lang="scss" />
